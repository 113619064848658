<template>
    <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">
        <b-form @submit.prevent="validate().then(save)">
            <b-col lg="6" md="8" sm="10">
                <base-input
                    type="text"
                    label="Calendar name"
                    v-model="name"
                    rules="required"
                    name="Template name"
                    :showErrors="false"
                    :showErrorBorder="false"
                    class="template-input"
                >
                </base-input>
            </b-col>
            <b-col lg="6" md="10" sm="10">
                <base-input
                    type="text"
                    label="Add URL"
                    v-model="templateUrl"
                    rules="required"
                    name="Template URL"
                    :showErrors="false"
                    :showErrorBorder="false"
                >
                </base-input>
          </b-col>
          <div class="d-flex justify-content-center">
            <button class="btn btn-outline-primary" @click.prevent="cancelingEditing" :disabled="!isEnableShortcut || !isTemplateChanged">
              Cancel
            </button>
            <button class="btn btn-primary" :disabled="invalid || !canSave">
              Save
            </button>
          </div>
        </b-form>
    </ValidationObserver>
</template>
<script>
import {mapActions} from 'vuex';
import {CREATE_USER_CALENDAR_REQUEST} from '@/store/storeActions';
export default {
  data: function() {
    return {
      id: '',
      name: '',
      templateUrl: '',
      isTemplateChanged: false,
    };
  },
  props: {
    isEnableShortcut: Boolean,
    selectedCalendarTemplate: Object,
    formCalendarBlocked: Boolean,
    canCreate: Boolean,
  },
  computed: {
    canSave: function() {
      return this.isEnableShortcut && this.isTemplateChanged;
    },
  },
  watch: {
    selectedCalendarTemplate: {
      handler: function(value) {
        this.initializeFields(value);
      },
      immediate: true,
    },
    name: {
      handler: function(value) {
        this.changeFormHandler('name', value);
      },
      immediate: true,
    },
    templateUrl: {
      handler: function(value) {
        this.changeFormHandler('templateUrl', value);
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      CREATE_USER_CALENDAR_REQUEST,
    ]),
    initializeFields: function(template) {
      if (!!template) {
        this.id = template.id;
        this.name = template.name;
        this.templateUrl = template.templateUrl;
      } else {
        this.id = null;
        this.name = '';
        this.templateUrl = '';
      }
    },
    changeFormHandler: function(field, value) {
      const blocked = this.selectedCalendarTemplate ? (this.selectedCalendarTemplate && value !==
      this.selectedCalendarTemplate[field]) : (value !== '');
      const unsavedFormData = {
        id: this.id,
        name: this.name,
        templateUrl: this.templateUrl,
      };
      this.isTemplateChanged = blocked;
      this.$emit('form-calendar-block-change', {blocked, unsavedFormData});
    },
    cancelingEditing: function() {
      this.initializeFields(this.selectedCalendarTemplate);
    },
    save: function() {
      const payload = {
        name: this.name,
        templateUrl: this.templateUrl,
      };
      !this.selectedCalendarTemplate || !this.selectedCalendarTemplate.id ? this.CREATE_USER_CALENDAR_REQUEST({payload}) : this.$emit('update-calendar-template', {payload, id: this.id});
      this.isTemplateChanged = false;
      this.$emit('enable-new-calendar-template');
    },
  },
};
</script>
<style lang="scss">
.template-input .form-group{
  border-radius: 10px;
  margin-bottom: 0 !important;
}
.greyed-out{
  background-color: #DCDCDC;
  cursor: default;
}
</style>
